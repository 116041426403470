<template>
    <main>
        <header id="js-header" class="u-shadow-v18 g-py-0 u-header  u-header--toggle-section u-header--change-appearance" data-header-fix-moment="300">
            <div class="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10" data-header-fix-moment-exclude="g-py-10" data-header-fix-moment-classes="u-shadow-v18 g-py-0">
                <nav class="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal fixed-top bg-white g-py-10">

                    <!-- Responsive Toggle Button -->
                    <button class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-minus-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                        <span class="hamburger hamburger--slider">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                        </span>
                        </span>
                    </button>
                    <!-- End Responsive Toggle Button -->

                    <!-- Logo -->
                    <router-link :to="{name: 'resume'}" class="navbar-brand g-pl-50" v-if="currentUserIsAuthenticated">
                        <img src="assets/img/logo/defensoria-logo-nuevo-h.png" alt="logo defensoria" style="max-height: 50px;">
                    </router-link>
                    <router-link :to="{name: 'begin'}" class="navbar-brand g-pl-50" v-else>
                        <img src="assets/img/logo/defensoria-logo-nuevo-h.png" alt="logo defensoria" style="max-height: 50px;">
                    </router-link>
                    <!-- End Logo -->

                    <!-- Navigation -->
                    <div class="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg" id="navBar">
                        <ul class="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto">
                            <li class="nav-item g-mx-10--lg g-mx-15--xl">
                                <router-link :to="{name: 'defenders'}" class="nav-link g-py-7 g-px-0">DEFENSORES</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl">
                                <router-link :to="{name: 'associates'}" class="nav-link g-py-7 g-px-0">ASOCIADOS</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl">
                                <router-link :to="{name: 'statutes'}" class="nav-link g-py-7 g-px-0">ESTATUTOS Y PROTOCOLOS</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl">
                                <router-link :to="{name: 'questions'}" class="nav-link g-py-7 g-px-0">PREGUNTAS FRECUENTES</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl">
                                <router-link :to="{name: 'contact'}" class="nav-link g-py-7 g-px-0">CONTACTO</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl" v-if="currentUserIsAuthenticated">
                                <router-link :to="{name: 'resume'}" class="nav-link g-py-7 g-px-0 menu-account-name"><v-icon>mdi-account</v-icon> {{currentUserInfo.name}}</router-link>
                            </li>
                            <li class="nav-item g-mx-10--lg g-mx-15--xl" v-if="currentUserIsAuthenticated">
                                <router-link :to="{name: 'logoutfront'}" class="nav-link g-py-7 g-px-0"><i class="fa fa-times-rectangle"></i> CERRAR SESIÓN</router-link>
                            </li>
                            <div class="d-lg-flex g-mx-15--xl g-pt-7" v-else>
                                <ul class="u-list-inline">
                                    <li class="list-inline-item">
                                        <router-link to="#" v-on:click.native="showDialogLogin=true" class="g-text-underline--none--hover g-color-gray-dark-v3">INGRESAR</router-link>
                                    </li>
                                    <li class="list-inline-item">
                                        <span>|</span>
                                    </li>
                                    <li class="list-inline-item">
                                        <router-link :to="{name: 'registrationSelectionUserType'}" class="g-text-underline--none--hover g-color-gray-dark-v3">REGISTRO</router-link>
                                    </li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                    <!-- End Navigation -->
                </nav>
            </div>

            <Login :showDialogLogin="showDialogLogin" @abort="showDialogLogin=false"/>
        </header>

        <router-view :key="$route.path"></router-view>
        
        <Footer/>
    </main>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import currentUserMixin from "@/mixins/currentUser";
    import currentClaimInCreationMixin from "@/mixins/currentClaimInCreation";
    import currentUpdateProfileMixin from "@/mixins/currentUpdateProfile";
    
    import Login from "@/components/PublicSite/Login";
    import Footer from "@/components/PublicSite/Footer";

    export default {
        mixins: [baseViewMixin, currentUserMixin, currentClaimInCreationMixin, currentUpdateProfileMixin],
        components: {Login,Footer},
        data() {
            return {
                showDialogLogin: false
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>